<template>
	<div class="supplier-quote-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Supplier Quote" filter="quoteNo" sort="receivedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="supplierQuotations"
		               v-on:add-new="addQuote"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import { format } from "date-fns"

export default {
	name: "SupplierQuoteTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Quote No.", key: "quoteNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-5"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Received At", key: "receivedAt", classBinding: [] },
			],
		}
	},
	
	computed: {
		supplierQuotations() {
			return this.$store.state.contents.supplierQuotations.map(quote => {
				const obj = {...quote};
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === quote.supplier);
				obj.supplier = supplier ? `${supplier.name} (${supplier.supplierNo})` : "N/A";
				obj.receivedAt = quote.receivedAt ? format(new Date(quote.receivedAt), "yyyy-MM-dd") : "N/A";
				obj.items = quote.items.length; // type Number
				return obj;
			});
		}
	},
	
	methods: {
		async addQuote() {
			await this.$router.push("/supplier/quotation/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/supplier/quotation/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
